import axios from 'axios'

/**
 * A wrapper around an axios instance, preconfigured to have a base URL and auth headers
 */
class ApiClient {
  constructor () {
    console.log(process.env)
    return axios.create({
      baseURL: process.env.VUE_APP_BACKEND_HOST,
      // withCredentials: true,
      headers: {
        // Authorization: `Bearer ${bearerToken}`
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
  }
}

export default ApiClient
